import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import ContentContainer from '../../components/ContentContainer';
import pexels from '../../components/jointAccount/assets/live-the-live.png';
import { zjcWebUrl, cloudfrontBaseUrl } from '../../lib/consts';

const PeopleBiking = `${cloudfrontBaseUrl}/joint-accounts/peoplebiking.jpg`;

const Anchor = () => {
  return (
    <>
      <Flex
        align={['flex-start', 'center']}
        background={`#787878 url(${PeopleBiking}) top center no-repeat`}
        backgroundPosition={['45% 75%', 'center']}
        backgroundSize="cover"
        minHeight="800px"
        style={{
          imageRendering: '-webkit-optimize-contrast',
        }}
      >
        <ContentContainer>
          <Flex direction="column">
            <Text
              textAlign={['center', 'left']}
              pt={26}
              fontSize={['3xl', '4xl']}
              fontWeight={theme.fontWeights.semibold}
              as="h3"
              color={colors.white}
            >
              Live The Life You Want
            </Text>
            <Text pt={2} mt={[0, 3]} textAlign={['center', 'left']} color={colors.white}>
              Take the first step and achieve
              <br /> your goals together.
            </Text>

            <Flex pt={3} mt={6} justify={['center', 'left']}>
              <Button
                height="2.5rem"
                border="1px"
                backgroundColor={colors.greenLight}
                _hover={{
                  backgroundColor: colors.darkGreenHover,
                }}
                onClick={() => {
                  window.location.href = zjcWebUrl;
                }}
              >
                Get started
              </Button>
            </Flex>
          </Flex>
        </ContentContainer>
      </Flex>
    </>
  );
};

export default Anchor;
