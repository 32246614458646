import React, { useEffect, useState } from 'react';
import { Flex, Text, Image } from '@chakra-ui/core';
import querystring from 'query-string';
import { Button } from '@zeta';
import { colors } from '@theme';
import Lottie from 'react-lottie';
import { H4 } from '@typography';
import ContentContainer from '../../components/ContentContainer';
import { getPendingInvitation, nextUrl } from '../../lib/invitations/utils';

import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

import JointAccountAnimation from '../../lotties/joint-account-animation.json';
import JointAccountMobileAnimation from '../../lotties/joint-account-mobile-animation.json';
import ZetaLogoLightmode from '@assets/ZetaLogoLightmode.svg';

const toSentence = (words, { andWord = ' and' } = {}) => {
  if (!words || !Array.isArray(words)) {
    return '';
  }
  const lastWord = words.slice(-1)[0];
  const priorWords = words.slice(0, -1);

  if (priorWords.length === 0) {
    return lastWord;
  }
  return `${priorWords.join(', ')}${andWord} ${lastWord}`;
};

const TopSection = () => {
  const [invite, setInvite] = useState({});

  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  } else {
    search = '';
  }

  const parsed = search ? querystring.parse(search) : {};
  const { token } = parsed;

  const getInvite = async () => {
    const i = await getPendingInvitation({ token });
    setInvite(i);
    return true;
  };

  useEffect(() => {
    if (token?.length) {
      getInvite();
    }
  }, []);

  const names = invite?.accountMembers?.map(m => m.firstName);
  const claimUrl = `${nextUrl}/welcome/additional?token=${token}`;

  return (
    <Flex align="center" direction="column" bg={colors.iceberg} pt={12}>
      <Flex
        background={colors.iceberg}
        mb={['1rem', '1rem']}
        justify={['center', 'center']}
      >
        <Image src={ZetaLogoLightmode} height="36px" />
      </Flex>
      <ContentContainer>
        <Flex align="center" direction="column" pt={[8, 4]}>
          <DesktopOnly>
            <Flex align="center" direction="column">
              {names?.length ? (
                <Text
                  as="h1"
                  textAlign="center"
                  fontSize={['24px', '32px']}
                  fontWeight="600"
                >
                  {toSentence(names)} want to
                  <br />
                  add you to their Zeta Account
                </Text>
              ) : null}
              <H4 as="h2" mt={8}>
                Shared banking made easy
              </H4>
            </Flex>
            <Flex justify={['center', 'center']} mt={16}>
              <Button
                style={{
                  height: '40px',
                  width: '121px',
                }}
                text="See invite"
                variant="tertiary"
                onClick={() => {
                  window.location.href = claimUrl;
                }}
              />
            </Flex>
            <Flex mt="20px" minHeight="730px">
              <Lottie
                isClickToPauseDisabled
                style={{
                  cursor: 'auto',
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: JointAccountAnimation,
                }}
              />
            </Flex>
          </DesktopOnly>

          <MobileOnly>
            <>
              {names?.length ? (
                <Text
                  as="h1"
                  textAlign="center"
                  fontSize="24px"
                  lineHeight="24px"
                  fontWeight="600"
                  pl={4}
                  pr={4}
                >
                  {toSentence(names)} want to add you to their Zeta Account
                </Text>
              ) : null}
              <Text
                as="h2"
                textAlign="center"
                fontSize="18px"
                lineHeight="22px"
                pt="22px"
              >
                Shared banking made easy
              </Text>
              <Flex justify={['center', 'center']} mb={16} mt={16}>
                <Button
                  style={{
                    height: '40px',
                    width: '121px',
                  }}
                  text="See invite"
                  variant="tertiary"
                  onClick={() => {
                    window.location.href = claimUrl;
                  }}
                />
              </Flex>
              <Flex mt="24px" minHeight="230px" maxWidth="100%" minWidth="100%">
                <Lottie
                  width="100vw"
                  style={{
                    cursor: 'auto',
                  }}
                  isClickToPauseDisabled
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: JointAccountMobileAnimation,
                  }}
                />
              </Flex>
            </>
          </MobileOnly>
          <Flex align="center" pb={['12px', '24px']} mt={['8px', -20]} justify="center">
            <Text
              ml={4}
              fontStyle="italic"
              width="200px"
              color={colors.primary}
              fontSize="10px"
              style={{ textAlign: 'center' }}
            >
              Zeta Joint Account is a deposit account powered by Piermont Bank, Member
              FDIC.
            </Text>
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default TopSection;
