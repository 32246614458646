import React from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/core';
import moment from 'moment';
import styled from 'styled-components';

import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import Link from '../Navigation/Link';
import theme, { colors } from '@theme';
import { SMALL_SCREEN } from '../styles/sizes';
import {
  Facebook,
  Instagram,
  Pinterest,
  YouTube,
  Twitter,
  Reddit,
  TikTok,
} from '../components/SocialIcons';
import NewsLetterSignup from '../components/home/newsLetterSignup';
import { AppStoreBadge } from '@zeta';

import { CURRENT_APY_RATE, CURRENT_VIP_APY_RATE } from '../lib/consts';

function FooterLink({ children, mr = 0, href, ...rest }) {
  return (
    <Flex mr={mr}>
      <Link href={href} noNewTab>
        <Text p={[1, 0]} color={colors.white} as="p" mb={3} {...rest}>
          {children}
        </Text>
      </Link>
    </Flex>
  );
}

export const DesktopOnly = styled.div`
  @media (max-width: ${SMALL_SCREEN}) {
    display: none;
  }
`;
export const MobileOnly = styled.div`
  @media (min-width: ${SMALL_SCREEN}) {
    display: none;
  }
`;

export default function Footer({ noSubscribe = false }) {
  return (
    <>
      {!noSubscribe && (
        <Flex bg={colors.greenLight} className="news-letter-signup">
          <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
            <NewsLetterSignup />
          </ContentContainer>
        </Flex>
      )}
      <Box bg={colors.black} py={6}>
        <ContentContainer pt={[4, 8]}>
          <DesktopOnly>
            <Flex
              flexDirection={'row'}
              flexWrap={['wrap', 'nowrap']}
              justifyContent="space-between"
              pb={6}
              mb={6}
              px={[4, 0]}
            >
              <Flex justify="center">
                <Box pr={[6]}>
                  <FooterLink href="/aboutus" fontWeight={theme.fontWeights.semibold}>
                    About
                  </FooterLink>
                  <FooterLink href="/diversity">Diversity</FooterLink>
                  <FooterLink href="/press">Press</FooterLink>
                  <FooterLink href="/aboutus">Team</FooterLink>
                  <FooterLink href="/careers">Careers</FooterLink>
                  <FooterLink href="/legal">Legal</FooterLink>
                  <FooterLink href="/security">Security</FooterLink>
                </Box>
              </Flex>

              <Box>
                <FooterLink
                  fontWeight={theme.fontWeights.semibold}
                  href="/magazine/articles"
                >
                  Magazine
                </FooterLink>
                <FooterLink href="/magazine/articles">Articles</FooterLink>
                <FooterLink href="/magazine/#guides-quizzes-tools-podcast">
                  Guides and Tools
                </FooterLink>
                <FooterLink href="/magazine/podcast">Podcast</FooterLink>
                <FooterLink href="/sentwithlove">#SentWithLove</FooterLink>
              </Box>
              <Box pr={[5]}>
                <FooterLink fontWeight={theme.fontWeights.semibold} href="/help">
                  Support
                </FooterLink>
                <FooterLink href="/help">FAQs</FooterLink>
                <FooterLink href="mailto:support@askzeta.com">
                  support@askzeta.com
                </FooterLink>
                <FooterLink href="https://card.askzeta.com/messages">
                  Secure Chat
                </FooterLink>
                <FooterLink href="https://askzeta.myshopify.com/">Swag</FooterLink>
              </Box>

              <Flex flexDirection="column" pt={[5, 0]}>
                <Flex>
                  <AppStoreBadge zjcDirect original platform="android" />
                </Flex>
                <Flex pt={5}>
                  <AppStoreBadge zjcDirect original platform="ios" />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction="row"
              style={{ justifyContent: 'space-between', width: '100%' }}
            >
              <Flex direction="column">
                <Flex mb={6}>
                  <Text color={colors.white} fontWeight={theme.fontWeights.semibold}>
                    Join Our Community
                  </Text>
                </Flex>
                <Flex direction="row">
                  <Flex width={['200px', '350px']} justify="space-between">
                    <Box>
                      <YouTube />
                    </Box>
                    <Box>
                      <Instagram />
                    </Box>
                    <Box>
                      <Facebook />
                    </Box>
                    <Box>
                      <Twitter />
                    </Box>
                    <Box>
                      <Pinterest />
                    </Box>
                    <Box>
                      <Reddit />
                    </Box>
                    <Box>
                      <TikTok />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                <Text color={colors.white}>
                  © {moment.utc().format('YYYY')} Zeta Help Inc. All rights reserved.
                </Text>
                <Flex pt={6} style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Text color={colors.white}>2261 Market Street #4718</Text>
                  <Text color={colors.white}>San Francisco, CA 94114</Text>
                </Flex>
              </Flex>
            </Flex>
          </DesktopOnly>
          <MobileOnly>
            <Flex flexDirection="column">
              <Flex flexDirection={'column'} pb={6} mb={6} px={[3, 0]}>
                <FooterLink href="/aboutus" fontWeight={theme.fontWeights.semibold}>
                  About
                </FooterLink>
                <Flex py={[4, 0]} mb={4} flexWrap="wrap">
                  <FooterLink href="/diversity">Diversity&nbsp;|&nbsp;</FooterLink>
                  <FooterLink href="/press">Press&nbsp;&nbsp;|</FooterLink>
                  <FooterLink href="/aboutus">Team&nbsp; |</FooterLink>
                  <FooterLink href="/careers">Careers&nbsp;&nbsp;|</FooterLink>
                  <FooterLink href="/partner">Partner&nbsp;&nbsp;&nbsp;|</FooterLink>
                  <FooterLink href="/legal">&nbsp;Legal&nbsp;&nbsp;|</FooterLink>
                  <FooterLink href="/security">Security</FooterLink>
                </Flex>
                <FooterLink
                  fontWeight={theme.fontWeights.semibold}
                  href="/magazine/articles"
                >
                  Magazine
                </FooterLink>
                <Flex py={[4, 0]} mb={4} flexWrap="wrap">
                  <FooterLink href="/magazine/articles">Articles &nbsp;|</FooterLink>
                  <FooterLink href="/magazine/#guides-quizzes-tools-podcast">
                    Guides&nbsp;&nbsp;|
                  </FooterLink>
                  <FooterLink href="/magazine/#guides-quizzes-tools-podcast">
                    Tools&nbsp;&nbsp;|
                  </FooterLink>
                  <FooterLink href="/magazine/podcast">Podcast&nbsp;|&nbsp;</FooterLink>
                  <FooterLink href="/sentwithlove">#SentWithLove</FooterLink>
                </Flex>
                <FooterLink fontWeight={theme.fontWeights.semibold} href="/help">
                  Support
                </FooterLink>
                <Flex py={[4, 0]} mb={4} flexWrap="wrap">
                  <FooterLink href="/help">FAQs&nbsp; |</FooterLink>
                  <FooterLink href="mailto:support@askzeta.com">
                    support@askzeta.com&nbsp;|&nbsp;
                  </FooterLink>
                  <FooterLink href="https://card.askzeta.com/messages">
                    Secure Chat&nbsp;&nbsp;|
                  </FooterLink>
                  <FooterLink href="https://askzeta.myshopify.com/">Swag</FooterLink>
                </Flex>
                <Flex justifyContent="space-evenly" flexDirection="row">
                  <Flex>
                    <AppStoreBadge zjcDirect original height={50} platform="android" />
                  </Flex>
                  <Flex ml={4}>
                    <AppStoreBadge zjcDirect original height={45} platform="ios" />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction={['column', 'row']} px={[6, 0]} alignItems="center">
              <Flex direction="column">
                <Flex mb={6} justifyContent="center">
                  <Text
                    color={colors.white}
                    fontWeight={theme.fontWeights.semibold}
                    center
                  >
                    Join Our Community
                  </Text>
                </Flex>
                <Flex direction="row">
                  <Flex width={['200px', '350px']} justify="space-between">
                    <Box>
                      <YouTube />
                    </Box>
                    <Box>
                      <Instagram />
                    </Box>
                    <Box>
                      <Facebook />
                    </Box>
                    <Box>
                      <Twitter />
                    </Box>
                    <Box>
                      <Pinterest />
                    </Box>
                    <Box>
                      <Reddit />
                    </Box>
                    <Box>
                      <TikTok />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" alignItems="center" mt={6}>
                <Text color={colors.white}>
                  © {moment.utc().format('YYYY')} Zeta Help Inc. All rights reserved.
                </Text>
                <Flex pt={6} style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Text color={colors.white}>2261 Market Street #4718</Text>
                  <Text color={colors.white}>San Francisco, CA 94114</Text>
                </Flex>
              </Flex>
            </Flex>
          </MobileOnly>

          <Divider pb={6} mb={6} color={colors.darkGray} />
          <Box px={[2, 0]}>
            <Text
              id="zeta-disclosure"
              as="p"
              color={colors.gray}
              fontSize={theme.fontSizes.xs}
              lineHeight={theme.lineHeights['5.5']}
            >
              To safely consume this site, we recommend reading this disclaimer. Any
              outbound links will take you away from Zeta, to external sites in the world
              wide web. Just so you know, Zeta doesn’t endorse any linked websites nor do
              we pay/bribe anyone to appear on here. Any reference to prices on the site
              are just estimates; actual prices are up to specific merchants and their
              current desire to charge you for things. Also, nothing on this website
              should be construed as investment advice. We’re here to share our favorite
              tools, tactics and tips for managing your money together. This content is
              for your responsible consumption. Please don’t see this as a recommendation
              to buy specific investments or go on a crypto-binge. Lastly, we 100% believe
              that personal finance is exactly that, personal. We may sometimes publish
              content on this website that has been created by affiliated or unaffiliated
              partners such as employees, advisors or writers. Unless we explicitly say
              so, these post do not necessarily represent the actual views or opinions of
              Zeta.
              <br />
              <br />
              By using this website, you understand the content presented is provided for
              informational purposes only and agree to our{' '}
              <Link
                style={{
                  color: colors.iceberg,
                  textDecoration: 'underline',
                }}
                href="/terms-of-use"
              >
                {' '}
                Terms of Use
              </Link>{' '}
              and
              <Link
                style={{
                  color: colors.iceberg,
                  textDecoration: 'underline',
                }}
                href="/privacy-policy"
              >
                {' '}
                Privacy Policy
              </Link>
              .
              <br />
              <br />
              <sup>1</sup>Zeta is a financial technology company, not a bank. Banking
              services provided by Piermont Bank; Member FDIC. All deposit accounts of the
              same ownership and/or vesting held at the issuing bank are combined and
              insured under an FDIC Certificate of $250,000 per depositor. The Zeta
              Mastercard® Debit Card is issued by Piermont Bank, Member FDIC, pursuant to
              license by Mastercard International Incorporated and can be used everywhere
              Mastercard is accepted.
              <br />
              <br />
              <sup>2</sup>Zeta Annual Percentage Yield (APY) is effective as of
              05/01/2023, for customers who qualify for VIP status. Minimum amount to open
              an account is $0.00. Minimum balance to earn the APY is $0.01. Interest
              rates are as follows: {CURRENT_VIP_APY_RATE} APY applies to the entire
              balance for customers who qualify for VIP status. Interest rates may change
              after the account is opened. Fees may reduce earnings.
            </Text>
            <br />
            <br />
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
}
