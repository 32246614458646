import React from 'react';
import { Box } from '@chakra-ui/core';

import { APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';

export default function ContentContainer({
  maxWidth = APP_CONTENT_DESKTOP_WIDTH,
  children,
  style = {},
  props,
}) {
  return (
    <Box
      mx="auto"
      style={style}
      maxWidth={maxWidth}
      minWidth={['100%', maxWidth]}
      {...props}
    >
      {children}
    </Box>
  );
}
