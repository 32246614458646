import React from 'react';
import Helmet from 'react-helmet';
import { Flex } from '@chakra-ui/core';

const Metadata = () => {
  const helmet = React.useMemo(
    () => (
      <Helmet>
        <title>Joint bank account: Simplify your finances with Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/joint-bank-account" />
        <meta
          name="description"
          content="Simplify your family finances with a joint bank account from Zeta. Explore the benefits of our joint banking solutions designed for couples and families."
        />
        <meta
          property="og:description"
          content="Simplify your family finances with a joint bank account from Zeta. Explore the benefits of our joint banking solutions designed for couples and families."
        />
        <meta
          name="keywords"
          content="joint bank account, joint banking, joint account, best joint bank account"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Master Money as a Team" />
        <meta property="og:site_name" content="Zeta | Master Money as a Team" />
        <meta property="og:url" content="https://www.askzeta.com/joint-bank-account" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
      </Helmet>
    ),
    [],
  );
  return <Flex>{helmet}</Flex>;
};

export default Metadata;
