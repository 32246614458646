import React, { useState } from 'react';
import { Flex, Text, Button, Heading, Input, Image } from '@chakra-ui/core';
import styled from 'styled-components';

import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import theme, { colors } from '@theme';
import validEmail from '../../lib/validEmail';
import subscribe from '../../lib/subscribeToList';
import { ArrowButton } from '@zeta';

const SubscribedSuccess = styled.div`
  opacity: 0;
  width: 0;
  white-space: nowrap;

  ${props =>
    props.subscribed &&
    `opacity: 1;
    width: 15rem;
    transition: all 1s ease;
  `};

  &:hover input {
    border-color: black;
  }
`;

const NewsLetterSignup = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const onEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const onSubmit = () => {
    if (validEmail(email)) {
      subscribe({
        email,
        list: 'master',
        source: 'content-hub-footer',
        sourceUrl: typeof window !== 'undefined' ? window.location.href : null,
      });
      setSubscribed(true);
    } else {
      alert(
        `'${email}' doesnt look like a valid email address.  Please correct it and try again.`,
      );
    }
  };

  return (
    <Flex width="100%" flexGrow="grow" justify="center">
      <Flex
        flexGrow="grow"
        justify={['auto', 'center']}
        direction={['column', 'row']}
        py={8}
        width="100%"
      >
        <Flex px={[0, 0]} width="100%" direction={['column', 'row']} justify="center">
          <DesktopOnly>
            <Text color={colors.primary} fontSize="2xl" fontWeight="bold">
              A newsletter designed to help
              <br />
              you achieve relationship goals.
            </Text>
          </DesktopOnly>
          <MobileOnly>
            <Text
              align="center"
              color={colors.primary}
              fontSize="2xl"
              px={[4, 0]}
              fontWeight="bold"
            >
              A newsletter designed to help you achieve relationship goals.
            </Text>
          </MobileOnly>

          <Flex
            ml={[0, 8]}
            pt={[5, 0]}
            pb={[3, 0]}
            justify={['center', 'auto']}
            align="center"
          >
            <SubscribedSuccess subscribed={subscribed}>
              <Input
                bg={colors.iceberg}
                value="You're on the list!"
                onChange={() => {}}
                border={'1px solid #000000'}
                borderRadius="10px"
                width={['12rem', '15rem']}
              />
            </SubscribedSuccess>

            {!subscribed && (
              <Input
                placeholder="Your favorite email"
                border={'1px solid #000000'}
                borderRadius="10px"
                width={['12rem', '15rem']}
                onChange={onEmailChange}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            )}

            {!subscribed && (
              <Flex
                className="newsletter-signup-submit"
                pl={6}
                justify="center"
                align="center"
              >
                <ArrowButton onClick={onSubmit} disabled={subscribed} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NewsLetterSignup;
